<template>
  <v-container grid-list-lg fluid px-lg-7 pt-lg-0>
    <v-layout row >
      <v-flex xs6 md3 lg2 d-flex  class="pa-1">
        <v-switch
          v-model="showOptions"
          label="Dashboards"
        ></v-switch>
      </v-flex>

      <!-- <v-flex xs6 md3 lg2 d-flex  class="pa-1">
        <v-switch
          v-model="slicers.show"
          label="Show Filters"
        ></v-switch>
      </v-flex> -->

      <v-flex xs6 md3 lg2 d-flex  class="pa-1" v-if="showContactsAndEvents && gurlOff">
        <v-switch
          v-model="contacts.show"
          label="Contacts"
           @change="showContactsChanged"
        ></v-switch>
      </v-flex>

      <v-flex xs6 md3 lg2 d-flex  class="pa-1"  v-if="showContactsAndEvents">
        <v-switch
          v-model="events.show"
          label="Events"
          @change="showEventsChanged"
        ></v-switch>
      </v-flex>

      <v-flex xs6 md3 lg2 d-flex class="pa-1" v-if="gurlOff">
        <v-switch
          v-model="slicers.distinct"
          label="Distinct"
          @change="filtersChanged(null)"
        ></v-switch>
      </v-flex>

      <v-flex xs6 md3 lg2 d-flex class="pa-1" v-if="gurlOff">
        <v-switch
          v-model="slicers.isSeed"
          label="Seed"
          @change="filtersChanged(null, 'isSeed')"
        ></v-switch>
      </v-flex>

      <v-flex xs6 md3 lg2 d-flex class="pa-1">
        <v-switch
          v-model="slicers.isGurl"
          label="GURL"
          @change="filtersChanged(null, 'isGurl')"
        ></v-switch>
      </v-flex>

    </v-layout>

    <v-layout row wrap v-if="showOptions" >
      <v-flex xs12 md3 d-flex v-if="!inPreviewMode" >
        <v-autocomplete
          item-text="name"
          item-value="_id"
          v-model="selectedDashboard"
          @change="dashboardChanged"
          :items="dashboards"
          label="Dashboard"
          prepend-icon="dashboard"
          single-line

        ></v-autocomplete>
      </v-flex>

      <!-- <v-flex xs12 md3 d-flex v-if="!inPreviewMode" >
        <v-autocomplete
          item-text="key"
          item-value="values"
          v-model="selectedTheme"
          @change="themeChanged"
          :items="themes"
          label="Themes"
          prepend-icon="palette"
          single-line
        ></v-autocomplete>
      </v-flex> -->

      <v-flex xs12 md3 d-flex v-if="canClone">
        <bt-dashboard-designer
          :max-chart-rows = "maxChartRows"
          btn-add-title="Create "
          :is-power-admin="isPowerAdmin"
          :btn-add-show="designerShowCreateButton"
          :btn-edit-title="designerCloneButtonTitle"
          :btn-edit-icon="designerCloneButtonIcon"
          :rdls-data="getReportList"
          :btn-edit-disabled="false"
          :dashboard-data="designerDDL"
          :isAdmin="isPowerAdmin"
          :isParent="(isParentAccount && isPowerUser) || (isParentAccount && isPowerAdmin)"
          @save-dashboard="dashboardSaved"
          @preview-dashboard="dashboardPreviewed"
          @cancel-dashboard="dashboardCancelled"
          :is-authorized="hasUserPermission('bi-dashboards-create')"
          :custom-fields="contacts.customFields"
        >
        </bt-dashboard-designer>
      </v-flex>

      <v-flex xs12 md3 d-flex v-if="canShare && !inPreviewMode" >

        <bt-dashboard-settings
          btn-title="Settings"
          v-model="dashboardDDL"
          :paid="parent.toString()"
          :said="account"
          :author="dashboardDDL.author"
          :is-power-user="isPowerUser"
          :is-admin="isAdminUser"
          :update-shared-with="updateSharedWith"
          :edit-dashboard="updateDashboardNameAndTag"
          :delete-dashboard="deleteDashboard"
        ></bt-dashboard-settings>
      </v-flex>

      <v-flex xs12 md3 d-flex pt-5 v-if="canGeneratePDF">
        <v-btn @click="exportToPDF" >Generate PDF</v-btn>
      </v-flex>

    </v-layout>

    <v-container grid-list-lg fluid px-lg-0 pt-lg-0 id="element-to-convert">

      <v-layout row wrap v-show="true">
        <v-flex xs12 md3 d-flex v-if="dashboard.filtersDefinition[0].show">

          <bt-autocomplete
            :is-included="true"
            included-label="Include"
            excluded-label="Exclude"
            :counter="dashboard.filtersDefinition[0].list.length"
            item-text="name"
            item-value="id"
            v-model="dashboard.filtersDefinition[0].selected"
            @change="filter0Changed('changed')"
            @blur="filter0Changed('blur')"
            @switch-changed="filter0SwitchChanged"
            :items="dashboard.filtersDefinition[0].list"
            :label="dashboard.filtersDefinition[0].name"
            prepend-icon="filter_list"
            single-line
            small-chips
            deletable-chips
            clearable
            :multiple="dashboard.filtersDefinition[0].multipleSelect"
          ></bt-autocomplete>
        </v-flex>

        <v-flex xs12 md3 d-flex v-if="dashboard.filtersDefinition[1].show">

          <bt-autocomplete
            :is-included="true"
            included-label="Include"
            excluded-label="Exclude"
            :counter="dashboard.filtersDefinition[1].list.length"
            item-text="name"
            item-value="id"
            v-model="dashboard.filtersDefinition[1].selected"
            @change="filter1Changed('changed')"
            @blur="filter1Changed('blur')"
            @switch-changed="filter1SwitchChanged"
            :items="dashboard.filtersDefinition[1].list"
            :label="dashboard.filtersDefinition[1].name"
            prepend-icon="filter_list"
            single-line
            small-chips
            deletable-chips
            clearable
            :multiple="dashboard.filtersDefinition[1].multipleSelect"
          ></bt-autocomplete>
        </v-flex>

        <v-flex xs12 md3 d-flex v-if="dashboard.filtersDefinition[2].show">

          <bt-autocomplete
            :is-included="true"
            included-label="Include"
            excluded-label="Exclude"
            :counter="dashboard.filtersDefinition[2].list.length"
            item-text="name"
            item-value="id"
            v-model="dashboard.filtersDefinition[2].selected"
            @change="filter2Changed('changed')"
            @blur="filter2Changed('blur')"
            @switch-changed="filter2SwitchChanged"
            :items="dashboard.filtersDefinition[2].list"
            :label="dashboard.filtersDefinition[2].name"
            prepend-icon="filter_list"
            single-line
            small-chips
            deletable-chips
            clearable
            :multiple="dashboard.filtersDefinition[2].multipleSelect"
          ></bt-autocomplete>
        </v-flex>

        <v-flex xs12 md3  v-show="dashboard.datePicker.type === 'range'" style="padding-top:20px">
          <BtDatePicker :dp-no-title="false" dd-label dp-dialog-title :dd-value="selectedDateRangePicker" @date-change="dataRangePickerChanged" v-model="slicers.btDateRange" dp-range="month"  :dp-max-range="365"> <!-- dashboard.datePicker.defaultId --> <!-- parseInt(dashboard.datePicker.maxRange) -->
          </BtDatePicker>
        </v-flex>

        <v-flex xs12 md3  v-show="dashboard.datePicker.type === 'day'" style="padding-top:20px">
          <BtDatePicker :dp-no-title="false" dd-label dp-dialog-title dd-value="T" @date-change="dayPickerChanged" v-model="slicers.btDay" dp-range="day">
          </BtDatePicker>
        </v-flex>

      </v-layout>

      <v-layout row wrap  v-for="row in dashboard.layout.rows" :key="row.id" >
        <v-flex v-bind="{[`md${cell.columns}`]: true}" xs12 v-for="cell in row.cells" :key="cell.id" >
          <v-card
            class="mx-auto card-outter"
            height="100%"
            :flat="dashboard.layout.flat"
            :loading="cell.loading"
            :outlined="dashboard.layout.outlined"
            :elevation="dashboard.layout.elevation"
            :raised="dashboard.layout.raised"
            >
            <!-- :headers-seq="cell.preFilters && cell.preFilters.hasOwnProperty('event_ID') ? cell.preFilters.event_ID : []" -->

            <bt-calculated-table v-if="cell.chartType==='CalculatedTable'"
                :sort-column="cell.sortColumn"
                :sort-order="cell.sortOrder"
                :report-id="cell.reportId"
                :chart-data="cell.chartData"
                :calculated-columns="cell.calculatedColumns"
                :options="cell.chartOptions"
                :row-dim="cell.rowDIM"
                :col-dim="cell.colDIM"
                @click="calculatedTableClicked(cell, ...arguments)"
                :custom-header="cell.customHeader"
                :headers-alignment="cell.headersAlignment"
                :headers-seq="(cell.eventsSequence) ? JSON.parse(cell.eventsSequence) : []"
            ></bt-calculated-table>

            <bt-clickable-table v-else-if="cell.chartType==='ClickableTable'"
              :chart-data="cell.chartData"
              :clickable-field="cell.clickableField"
              :event-fields="cell.eventFields"
              :groupby-field="cell.groupbyField"
              :headers="cell.headers"
              @click="calculatedTableClicked(cell, ...arguments)"
            ></bt-clickable-table>

            <!-- :has-kpi2="!cell.useMasterDateRange" -->
            <bt-kpi
                v-else-if="cell.hasOwnProperty('kpi1')"
                :template-name="templateName(cell)"
                :title="cell.title"
                :icon="cell.icon"
                :kpi1="parseInt(cell.kpi1)"
                :kpi2="parseInt(cell.kpi2)"
                :kpi2Title="cell.kpi2Title"
                @kpi1-click="kpi1Clicked(cell)"
                @kpi2-click="kpi2Clicked(cell)"
                :gauge-colors="selectedTheme.split(',')"
                :format-numbers="cell.formatNumbers"
                :gauge-max-value="cell.gaugeMaxValue"
            ></bt-kpi>

            <GChart
                v-else-if="!cell.hasOwnProperty('kpi1') && cell.chartType!=='CalculatedTable'"
                style="cursor:pointer"
                :ref="'gchart' + cell.id"
                :type="cell.chartType"
                :data="cell.chartData"
                :options="cell.chartOptions"
                :settings="googleChartSettings"
                :events="chartEvents"
            />

            <v-card-title class="justify-center subtitle-2 py-0" v-if="!cell.hasOwnProperty('kpi1')">
              <h4>{{cell.title}}</h4>
            </v-card-title>
            <v-card-actions class="card-actions py-0" v-if="!cell.hasOwnProperty('kpi1')">
              <!-- <v-btn icon>
                <v-icon small left>cloud_download</v-icon>
              </v-btn> -->

              <v-btn icon>
                <v-icon small left @click="changeChartType(cell)">table_chart</v-icon>
              </v-btn>

              <bt-google-chart
                    :cell-data="cell"
              ></bt-google-chart>

              <!-- <v-progress-circular
                v-if="cell.loading"
                :size="15"
                :width="1"
                indeterminate
                color="primary"
              ></v-progress-circular> -->
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row wrap v-if="contacts.show" align-center class="pa-1">
        <v-flex xs12 md12 d-flex class="pa-0">
          <v-switch
            v-model="contacts.showOptions"
            label="Options"
          ></v-switch>
        </v-flex>
        <v-flex xs12 md2 d-flex v-if="contacts.showOptions"> <!-- offset-md5 -->
          <v-autocomplete
            v-model="contacts.searchField"
            :items="dashboard.selectedContactsStandardFields"
            label="Search by: "
            prepend-icon="view_column"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs12 md3 d-flex  v-if="contacts.showOptions">
          <v-text-field label="search" v-model="contacts.search" prepend-icon="search"></v-text-field>
        </v-flex>
        <v-flex xs12 md2 d-flex  v-if="contacts.showOptions">
          <v-btn small @click="contactsSearchIsClicked">
            <span>Search</span>
            <v-icon right>search</v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs12 md2 d-flex offset-md1  v-if="contacts.showOptions">

          <BtDownloadSettings
            loading-message="processing..."
            :message="contacts.downloadMessage"
            @downloadSettings-changed="contactsDownloadSettingsChanged"
            :is-authorized="hasUserPermission('bi-reports-download')"
            no-permission-message="Please call Power User for access"

          >
          </BtDownloadSettings>

        </v-flex>
        <v-flex xs12 md2 d-flex   v-if="contacts.showOptions">
          <BtCronScheduler
            :instruction="contacts.cronInstruction"
            btn-label="Scheduler"
            @click="contactsSchedulerClicked"
            :is-authorized="hasUserPermission('scheduler-schedules-list')"
            no-permission-message="Please call Power User for access"
            :rdls="this.schedulerReportList"
            />
        </v-flex>
        <v-flex xs12 md8 d-flex v-if="contacts.showOptions">
          <v-autocomplete
            v-model="dashboard.selectedContactsStandardFields"
            :counter="contactStandardFieldsSorted.length"
            :items="contactStandardFieldsSorted"
            label="Standard Fields"
            prepend-icon="view_column"
            multiple
            small-chips
            deletable-chips
            clearable
            @blur="selectedContactsStandardFieldsChanged"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs12 md4 d-flex v-if="contacts.showOptions">
          <v-autocomplete
            v-model="contacts.customFieldSelections"
            :items="contacts.customFields"
            :counter="contacts.customFields.length"
            label="Custom Fields"
            prepend-icon="view_column"
            multiple
            small-chips
            deletable-chips
            clearable
            @blur="customFieldSelectionsChanged"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs12 md12  >
          <v-data-table
            class="elevation-4"
            :headers="contactListHeaders"
            :items="contacts.list"
            :loading="contacts.loading"
            :options.sync="contacts.pagination"
            :server-items-length="contacts.count"
            :footer-props="{
                itemsPerPageOptions: [5,10,15,20,25]
            }"
            dense
          >
            <template v-slot:item.action="{ item }">
              <div @click="getContactProfile(item, 'id')">
                <bt-contact-profile :contact-data="contactData"  >
                </bt-contact-profile>
              </div>
            </template>

          </v-data-table>
        </v-flex>
      </v-layout>

      <v-layout row wrap v-if="events.show" align-center >
        <v-flex xs12 md12 d-flex class="pa-0">
          <v-switch
            v-model="events.showOptions"
            label="Options"
          ></v-switch>
        </v-flex>
        <v-flex xs12 md2 d-flex v-if="events.showOptions"> <!-- offset-md5 -->
          <v-autocomplete
            v-model="events.searchField"
            :items="dashboard.selectedEventsFields"
            label="Search by: "
            prepend-icon="view_column"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs12 md3 d-flex  v-if="events.showOptions">
          <v-text-field label="search" v-model="events.search" prepend-icon="search"></v-text-field>
        </v-flex>
        <v-flex xs12 md2 d-flex  v-if="events.showOptions">
          <v-btn small @click="eventsSearchIsClicked">
            <span>Search</span>
            <v-icon right>search</v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs12 md2 d-flex offset-md1  v-if="events.showOptions">

          <BtDownloadSettings
            loading-message="processing..."
            :message="events.downloadMessage"
            @downloadSettings-changed="eventsDownloadSettingsChanged"
            :is-authorized="hasUserPermission('bi-reports-download')"
            no-permission-message="Please call Power User for access"

          >
          </BtDownloadSettings>

        </v-flex>
        <v-flex xs12 md2 d-flex   v-if="events.showOptions">

          <BtCronScheduler
            :instruction="events.cronInstruction"
            btn-label="Scheduler"
            @click="eventsSchedulerClicked"
            :is-authorized="hasUserPermission('scheduler-schedules-list')"
            no-permission-message="Please call Power User for access"
            :rdls="this.schedulerReportList"
            />
        </v-flex>
        <v-flex xs12 md12 d-flex v-if="events.showOptions">
          <v-autocomplete
            v-model="dashboard.selectedEventsFields"
            :items="eventHeadersSorted"
            :counter="eventHeadersSorted.length"
            label="Columns"
            prepend-icon="view_column"
            multiple
            small-chips
            deletable-chips
            clearable
            @blur="selectedEventsFieldsChanged"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs12 md12  >
          <v-data-table
            class="elevation-4"
            :headers="eventListHeaders"
            :items="events.list"
            :loading="events.loading"
            :options.sync="events.pagination"
            :server-items-length="events.count"
            :footer-props="{
                itemsPerPageOptions: [5,10,15,20,25]
            }"
            dense
          >
            <template v-slot:item.action="{ item }">
              <div @click="getContactProfile(item, 'contact_id')">
                <bt-contact-profile :contact-data="contactData">
                </bt-contact-profile>
              </div>
            </template>

          </v-data-table>
        </v-flex>
      </v-layout>

    </v-container>

  </v-container>

</template>

<script>
import { GChart } from 'vue-google-charts'
import { truncate } from 'fs';
import axios from 'axios'
import BtDatePicker from './BtDatePicker'
import BtCronScheduler from './BtCronScheduler'
import BtDownloadSettings from './BtDownloadSettings'
import BtKpi from './BtKpi'
import BtCalculatedTable from './BtCalculatedTable.vue'
import BtClickableTable from './BtClickableTable.vue'
import {BtDateRange} from '../helpers/BtDateRange'
import {SampleData} from '../helpers/SampleData'
import BtContactProfile from './BtContactProfile'
import BtDashboardDesigner from "./BtDashboardDesigner.vue";
import BtAutocomplete from "./BtAutocomplete.vue";
import BtGoogleChart from "./BtGoogleChart.vue";
//import BtDashboardSharing from "./BtDashboardSharing.vue";
import BtDashboardSettings from "./BtDashboardSettings.vue";
import html2pdf from "html2pdf.js";



function delay(miliseconds){
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, miliseconds)
    })
}

export default {
  components: {
    GChart,
    BtDatePicker,
    BtCronScheduler,
    BtDownloadSettings,
    BtKpi,
    BtCalculatedTable,
    BtContactProfile,
    BtDashboardDesigner,
    BtAutocomplete,
    BtGoogleChart ,
    BtDashboardSettings,
    BtClickableTable
  },
  data () {
    return {
      canClone: true,
      canGeneratePDF: true,
      portalState: [],
      selectedDateRangePicker: '6',
      maxChartRows: 4,
      designerShowCreateButton:true,
      designerCloneButtonTitle:"Clone",
      designerCloneButtonIcon:"content_copy",
      inPreviewMode: false,
      filterReportList: null,
      chartReportList: null,
      schedulerReportList: null,
      designerDDL: null, //new SampleData().designerDDL,
      dashboardDDL: null,
      googleChartSettings: { packages: ['corechart', 'table', 'map', 'geochart'], mapsApiKey: process.env.VUE_APP_GOOGLE_API_KEY },
      dateFunc: new BtDateRange(),
      showContactsAndEvents: false,
      showOptions: true,
      dashboard: {},
      dashboards: [],
      selectedDashboard: '',
      themes: [
        { key: "Watery Blue-Greens", values: "#003b46,#07575b,#66a5ad,#c4dfe6"},
        { key: "Exotic & High-Impact", values: "#0f1f38,#8e7970,#f55449,#1b4b5a"},
        { key: "Fresh Greens", values: "#265c00,#68a225,#b3de81,#b3de99"},
        { key: "Crisp & Dramatic", values: "#505160,#68829e,#aebd38,#598234"},
        { key: "Day & Night", values: "#011a27,#063852,#f0810f,#e6df44"},
        { key: "Spicy Neutrals", values: "#af4425,#662e1c,#ebdcb2,#c9a66b"},
        { key: "Golden Afternoon", values: "#882426,#cdbea7,#323030,#c29545"}
      ],
      selectedTheme: '#0f1f38,#8e7970,#f55449,#1b4b5a',

      selectedKPI: {
        cell: null,
        daterangeName: null
      },

      chartEvents: {
        'select': () => {

          let selections = []

          Object.entries(this.$refs).forEach(entry => {
            let key = entry[0];
            let value = entry[1];

            if(value.length>0 && value[0].chartObject.getSelection().length > 0){
              selections.push({key: key, selection: value[0].chartObject.getSelection()})
            }
          });
          //console.log('chart selections: ', selections);
          this.filtersChanged(selections);

        }
      },

      slicers: {
        show: false,
        distinct: true,
        isSeed: false,
        isGurl: false,
        filtersId: [],
        //programs: [],
        //selectedPrograms: [0],
        //apps: [],
        //selectedApps: [0],
        //goals:[],
        //selectedGoals: [0],
        btDateRange: [],
        btDay: "",
        filters: {},
        selectionFilter: {}
      },

      events: {
        list: [],
        count: 0,
        headers: [
          'eventtimestamp',
          'purl',
          'event_id',
          'servicetype_id',
          'goal_id',
          'program_id',
          'campaign_id',
          'inbound_id',
          'inbounddetail_id',
          'forminput_id',
          'outbound_id',
          'outboundschedule_id',
          'formresponse',
          'score',
          'duration',
          'linkname',
          'eventoption',
          'ps_listid',
          'ps_recordid',
          'visit_ip',
          'browser',
          'device'
        ],
        // headerSelections: [
        //   'eventtimestamp',
        //   'purl',
        //   'event_id',
        //   'servicetype_id',
        //   'goal_id',
        //   'program_id',
        //   'inbound_id',
        //   'inbounddetail_id',
        // ],
        pagination: {
          itemsPerPage: 5
        },
        search: '',
        searchField: 'purl',
        loading: false,
        show: false,
        showOptions: false,
        cronInstruction: {},
        downloadMessage: ""
      },

      contacts: {
        list: [],
        count: 0,
        standardFields: [
          'id',
          'purl',
          'firstname',
          'middlename',
          'lastname',
          'prefix',
          'suffix',
          'title',
          'gender',
          'analytic_str_1',
          'analytic_str_2',
          'accountid',
          'birthdate',
          'anniversary',
          'password',
          'company',
          'address1',
          'address2',
          'city',
          'state',
          'zip',
          'zipplus4',
          'deliverypointindicator',
          'country',
          'mobile',
          'phone',
          'extension',
          'fax',
          'email',
          'website',
          'twitteraccount',
          'linkedinaccount',
          'facebookaccount',
          'photourl'
        ],
        // standardFieldSelections: [
        //   'id',
        //   'purl',
        //   'email',
        //   'address1',
        //   'city',
        //   'state',
        //   'zip'
        // ],
        customFields: [],
        customFieldSelections: [],
        search: '',
        searchField: 'id',
        pagination: {
          itemsPerPage: 5
        },
        loading: true,
        show: false,
        showOptions: false,
        cronInstruction: {},
        downloadMessage: ""
      },

      contactData: {}
    }
  },
  async mounted() {
    //console.log(this.filterReportList);
    //console.log(this.chartReportList);
    try {
      this.portalState = [];

      const filterReportListParams = new URLSearchParams();
      filterReportListParams.append('reportType', 'filter');
      const filterReportList = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports', method: 'GET', params: filterReportListParams });
      this.filterReportList = filterReportList.data;

      const chartReportListParams = new URLSearchParams();
      chartReportListParams.append('reportType', '2da');
      const chartReportList = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports', method: 'GET', params: chartReportListParams });
      this.chartReportList = chartReportList.data;



      const resp = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/dashboards', method: 'GET' })
      this.dashboards = resp.data.sort((a, b) => (a.name > b.name) ? 1 : -1);
      this.filterDashboardListAgainstPreferences();

      await this.getSchedulerReportList();

      await this.filterDashboardList();

      await this.dashboardChanged();

    } catch (error) {
      this.$store.dispatch('logout')
      .then(() => {
        this.$router.push('/')
      })
      //alert('error on mount: ', error)
    }



  },
  methods: {
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
				margin:       0.1,
        filename:     'dashboard.pdf',
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 1 },
        pagebreak: {mode: 'avoid-all'},
        jsPDF:        { unit: 'in', format: 'letter', orientation: 'l' }
			});

      alert('Completed the PDF generation!');
    },
    getSchedulerReportList: async function(){
      const schedulerReportListParams = new URLSearchParams();
      schedulerReportListParams.append('reportType', 'scheduler');
      const schedulerReportList = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports', method: 'GET', params: schedulerReportListParams });
      this.schedulerReportList = schedulerReportList.data.map( item => {
        return item.name
      });
      console.log('this.schedulerReportList:', this.schedulerReportList);
    },
    getPortalState: function(){
      this.portalState = this.$store.getters.portalState;
    },
    getUserAccountPortalState: function(){
      this.getPortalState();
      
      let userAccountState = null;
      
      try {
        if(!Array.isArray(this.portalState)){
          this.portalState = [];
        }

        userAccountState = this.portalState.find( item => item.aid == this.$store.getters.user.account && item.email == this.$store.getters.user.email);
      
        if(!userAccountState){
          userAccountState = { aid: this.$store.getters.user.account, email: this.$store.getters.user.email }
          this.portalState.push(userAccountState);
          return userAccountState;
        }
      } catch (error) {
        alert(JSON.stringify(this.$store.getters.portalState));
      }

      return userAccountState;
    },
    setUserAccountToPortalState: function(propertyName, propertyValue){
      let userAccountState = this.getUserAccountPortalState();

      if(userAccountState === null)
        return;

      if(!userAccountState.bi){
        userAccountState.bi = {};
      }

      userAccountState.bi[propertyName] = propertyValue;

      //alert(JSON.stringify(this.portalState));
      this.$store.commit('setPortalState', this.portalState);
    },
    filterDashboardList: async function(){
      //------------filter dashboards if the account exist in accounts.json--------------------
      try {


        const accountsArray = await (await fetch(process.env.BASE_URL + "accounts.json")).json();
        const currentAccount =  accountsArray.find(item => {
          return item.paid == this.parent;
        });

        if(currentAccount && currentAccount.designerRows){
          this.maxChartRows = currentAccount.designerRows;
        }

        let isDefaultDashboardExist = false;
        if(currentAccount){
          isDefaultDashboardExist = this.dashboards.some(item => {
            //return item.name == currentAccount.defaultDashboard;
            return currentAccount.dashboardList.includes(item.name);
          })
        }

        if(isDefaultDashboardExist){

          // const tempId = this.dashboards.find( (item) => {
          //   return item.name === currentAccount.defaultDashboard;
          // });

          if(!this.isAdminUser && !this.isPowerUser && !this.hasUserPermission('bi-dashboards-create')){
            this.dashboards = this.dashboards.filter( item => {
              return currentAccount.dashboardList.includes(item.name);
            })
            
          }

          //this.selectedDashboard = tempId._id;
          this.selectedDashboard = this.dashboards[0]._id;

        } else {
          const tempId = this.dashboards.find( (item) => {
            return item.name === "Channel Dashboard";
          });

          if(tempId){
            this.selectedDashboard = tempId._id;
          } else {
            this.selectedDashboard = this.dashboards[0]._id;
          }

        }

        let userAccountState = this.getUserAccountPortalState();
        if(userAccountState && userAccountState.bi && userAccountState.bi.selectedDashboard){
          this.selectedDashboard = userAccountState.bi.selectedDashboard;
        }

        if(userAccountState && userAccountState.bi && userAccountState.bi.selectedDateRange){
          this.selectedDateRangePicker = userAccountState.bi.selectedDateRange;
        } else {
          this.selectedDateRangePicker = this.dashboard.datePicker.defaultId;
        }

        
      } catch (error) {
        console.log("filter dashboards error:", error);
        this.selectedDashboard = this.dashboards[0]._id;
      }

    //--------------------------------------------------------------------------------------------

    },
    templateName: function(cell){
      if(cell.useMasterDateRange){
        return "master-date-range"
      }
      else if(cell.templateName === "ratio-with-master-date-range"){
        return "ratio-with-master-date-range";
      }
      else if(cell.templateName === "master-date-range"){
        return "master-date-range";
      }
      return "internal-date-range";
    },
    async updateSharedWith(obj){
      //alert(JSON.stringify(obj));
      try {
        let putObject = {
          sharedWith: obj
        }
        const postResult = await axios.put(process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/dashboards/' + this.dashboardDDL._id , putObject);
        return '';
      } catch (error) {
        console.log('updateSharedWith error: ', JSON.stringify(error))
        return 'Error sharing the dashboard. Call administrator';
      }

    },
    async updateDashboardNameAndTag(dashboardObject){

      try {
        let putObject = {
          name: dashboardObject.name,
          tags: dashboardObject.tags
        }
        const putResult = await axios.put(process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/dashboards/' + this.dashboardDDL._id , putObject);
      } catch (error) {
        return 'Error renaming the dashboard. Call administrator';
      }

      for(const dash of this.dashboards){
          if(dash._id === dashboardObject._id){
            dash.name = dashboardObject.name;
            //this.dashboardChanged();
            break;
          }
      }

      this.dashboards = this.dashboards.filter(item => {
        return true;
      })

      return '';
    },
    async deleteDashboard(dashboardObject){

      try {

        const deleteResult = await axios.delete(process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/dashboards/' + this.dashboardDDL._id);
      } catch (error) {
        return 'Error renaming the dashboard. Call administrator';
      }

      this.dashboards = this.dashboards.filter(item => {
        if(item._id === dashboardObject._id){
          return false;
        }else{
          return true;
        }
      })

      this.selectedDashboard = this.dashboards[0]._id;
    },
    async filter0SwitchChanged(included){
      //alert('filter0 switch: ' + included);
      this.dashboard.filtersDefinition[0].useNotIn = !included;

      if(this.dashboard.filtersDefinition[0].selected.length > 0){
        const params = this.convertFiltersToQueryParams();

        this.filtersChanged(null, 'filter0');

        if(this.dashboard.filtersDefinition[1].show)
          this.getFilter1FromApi(params).then()
        if(this.dashboard.filtersDefinition[2].show)
          this.getFilter2FromApi(params).then()

      }
    },
    async filter1SwitchChanged(included){
      //alert('filter1 switch: ' + included);
      this.dashboard.filtersDefinition[1].useNotIn = !included;

      if(this.dashboard.filtersDefinition[1].selected.length > 0){
        const params = this.convertFiltersToQueryParams();

        this.filtersChanged(null, 'filter1');

        if(this.dashboard.filtersDefinition[0].show)
          this.getFilter0FromApi(params).then()
        if(this.dashboard.filtersDefinition[2].show)
          this.getFilter2FromApi(params).then()

      }
    },
    async filter2SwitchChanged(included){
      //alert('filter2 switch: ' + included);
      this.dashboard.filtersDefinition[2].useNotIn = !included;

      if(this.dashboard.filtersDefinition[2].selected.length > 0){
        const params = this.convertFiltersToQueryParams();

        this.filtersChanged(null, 'filter2');

        if(this.dashboard.filtersDefinition[0].show)
          this.getFilter0FromApi(params).then()
        if(this.dashboard.filtersDefinition[1].show)
          this.getFilter1FromApi(params).then()

      }
    },
    async selectedContactsStandardFieldsChanged(){
        await this.getContactsFromApi(true);
    },
    async customFieldSelectionsChanged(){
        await this.getContactsFromApi(true);
    },
    async selectedEventsFieldsChanged(){
        await this.getEventsFromApi(true);
    },
    async showContactsChanged(){
      if(this.contacts.show)
        await this.getContactsFromApi(false);
    },
    async showEventsChanged(){
      if(this.events.show)
        await this.getEventsFromApi(false);
    },
    async dashboardSaved(value) {

      if(value.sharedWith.users.length == 0){
        value.sharedWith.users.push(this.$store.getters.user.email);
      }
      value.author = this.$store.getters.user.email;


      try {
        if(value._id)
          delete value._id;
        //navigator.clipboard.writeText(JSON.stringify(value)).then();
        const postResult = await axios.post(process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/dashboards/' , value);
        const resp = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/dashboards', method: 'GET' })
        this.dashboards = resp.data.sort((a, b) => (a.name > b.name) ? 1 : -1); //resp.data;
        this.filterDashboardListAgainstPreferences();

        this.selectedDashboard = this.dashboards[this.dashboards.length - 1]._id;
        this.designerDDL = null;
        this.inPreviewMode = false;
        //navigator.clipboard.writeText(JSON.stringify(postResult)).then();

      } catch (error) {
          this.designerDDL = value;
          this.inPreviewMode = true;

          if(error.response && error.response.status && 403 === error.response.status)
              alert('User has no permission to create Dashboard.')
          else
            alert('Could not save the dashboard. Be sure the name is unique!');
      }

      await this.dashboardChanged();

    },
    async dashboardPreviewed(value) {
      try {
        //console.log(JSON.stringify(value));
        this.designerDDL = value;
        this.inPreviewMode = true;

        //navigator.clipboard.writeText(JSON.stringify(value)).then();

        await this.dashboardChanged();

      } catch (error) {
        alert(JSON.stringify(error));
      }



    },
    async dashboardCancelled() {
      this.designerDDL = null;
      this.inPreviewMode = false;
      await this.dashboardChanged();
    },
    getContactProfile: async function(item, idFieldName){
      let self = this;
      self.contactData = {};

      let params = null;

      if(this.selectedKPI.cell != null){
        if(this.selectedKPI.usePrefilters2){
          params = this.createKpiQueryParams(this.selectedKPI.daterangeName, this.selectedKPI.cell, true);
        }
        else
          params = this.createKpiQueryParams(this.selectedKPI.daterangeName, this.selectedKPI.cell);
      }
      else
        params = this.convertFiltersToQueryParams();

      params.append('contact_id', item[idFieldName]);

      let contact = { standardFields: null, customFields: null, events: null, scores: null};

      try {
        let contactStandardData = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/account-contact-standard' , method: 'GET', params: params });
        contact.standardFields = contactStandardData.data[0];
      }
      catch(err){
        //console.log(err);
      }

      try {
        let contactEvents = await this.getContactEventsFromApi(item[idFieldName]);
        contact.events = contactEvents;
      }
      catch(err){
        //console.log(err);
      }

      try {
        let scores = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/account-groupby-score-day' , method: 'GET', params: params });
        contact.scores = scores.data;
      }
      catch(err){
        //console.log(err);
      }

      try {

        let contactCustomData = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/account-contact-custom' , method: 'GET', params: params });
        if(contactCustomData && contactCustomData.data.length > 0 && contactCustomData.data[0].customfieldjson)
          contact.customFields = JSON.parse(contactCustomData.data[0].customfieldjson);
      }
      catch(err){
        //console.log(err);
      }

      self.contactData = contact;

    },
    kpi1Clicked: async function(cell){
      if (cell.reportId === 'account-count-contacts-all') return;

      if(cell.selectedEventsFields && cell.selectedEventsFields.length > 0){
        this.dashboard.selectedEventsFieldsTemp = this.dashboard.selectedEventsFields;
        this.dashboard.selectedEventsFields = cell.selectedEventsFields;
      }
      this.selectedKPI.cell = cell;
      this.selectedKPI.daterangeName = cell.kpi1DateRange;
      this.selectedKPI.usePrefilters2 = false;
      await this.getContactsFromApi(false);
      await this.getEventsFromApi(false);

      //alert(`You clicked kpi1 in ${cell.title} with value: ${cell.kpi1}`);
    },
    kpi2Clicked: async function(cell){
      if (cell.reportId === 'account-count-contacts-all') return;

      if(cell.selectedEventsFields && cell.selectedEventsFields.length > 0){
        this.dashboard.selectedEventsFieldsTemp = this.dashboard.selectedEventsFields;
        this.dashboard.selectedEventsFields = cell.selectedEventsFields;
      }
      this.selectedKPI.cell = cell;
      this.selectedKPI.daterangeName = cell.kpi2DateRange;
      this.selectedKPI.usePrefilters2 = false;
      if(cell.templateName === "ratio-with-master-date-range"){
        this.selectedKPI.usePrefilters2 = true;
      }
      await this.getContactsFromApi(false);
      await this.getEventsFromApi(false);

      //alert(`You clicked kpi2 in ${cell.title} with value: ${cell.kpi2}`);
    },
    contactsSchedulerClicked: async function(params) {
      try {
        await this.downloadContacts(null, true);
      } catch (error) {
        alert(error)
      }
    },
    contactsDownloadSettingsChanged: async function(obj){
      //console.log(JSON.stringify(obj));
      try {
        await this.downloadContacts(obj);
        this.contacts.downloadMessage = obj.fileName + ' file is ready!';
      } catch (error) {
        alert(error)
      }

    },
    eventsSchedulerClicked: async function(params) {
      try {
        await this.downloadEvents(null, true);
      } catch (error) {
        alert(error)
      }
    },
    eventsDownloadSettingsChanged: async function(obj){
      //console.log(JSON.stringify(obj));
      try {
        await this.downloadEvents(obj);
        this.events.downloadMessage = obj.fileName + ' file is ready!';
      } catch (error) {
        alert(error)
      }

    },
    changeChartType: function(cell){
      //console.log("changeChartType is fired!")
      if(cell.chartType != "Table") {
        cell.tempChartType = cell.chartType;
        cell.chartType = "Table"
      }
      else {
        cell.chartType = cell.tempChartType;
      }
    },
    setSlicerOnFilterChange: function(filterId){
      if(this.dashboard.filtersDefinition[filterId].hasOwnProperty('filter_custom_field') && this.dashboard.filtersDefinition[filterId].selected.length > 0){
        this.slicers.filters[this.dashboard.filtersDefinition[filterId].id] = this.dashboard.filtersDefinition[filterId].filter_custom_field;
        this.slicers.filters['custom_Field'] = this.dashboard.filtersDefinition[filterId].selected;
      }
      else{
        this.slicers.filters[this.dashboard.filtersDefinition[filterId].id] = this.dashboard.filtersDefinition[filterId].selected;
      }

      if(this.dashboard.filtersDefinition[filterId].selected.length == 0){
        delete this.slicers.filters[this.dashboard.filtersDefinition[filterId].id]
        if(this.dashboard.filtersDefinition[filterId].hasOwnProperty('filter_custom_field')){
          delete this.slicers.filters['custom_Field'];
        }
      }
    },
    filter0Changed: function(eventType){

      if(eventType === 'changed' && this.dashboard.filtersDefinition[0].multipleSelect){
        return;
      }

      this.setSlicerOnFilterChange(0);

      const params = this.convertFiltersToQueryParams();

      this.filtersChanged(null, 'filter0');

      if(this.dashboard.filtersDefinition[1].show)
        this.getFilter1FromApi(params).then()
      if(this.dashboard.filtersDefinition[2].show)
        this.getFilter2FromApi(params).then()
    },
    filter1Changed: function(eventType){

      if(eventType === 'changed' && this.dashboard.filtersDefinition[1].multipleSelect){
        return;
      }

      // this.slicers.filters[this.dashboard.filtersDefinition[1].id] = this.dashboard.filtersDefinition[1].selected;
      // if(this.dashboard.filtersDefinition[1].selected.length == 0){
      //   delete this.slicers.filters[this.dashboard.filtersDefinition[1].id]
      // }

      this.setSlicerOnFilterChange(1);


      const params = this.convertFiltersToQueryParams();

      this.filtersChanged(null, 'filter1');

      if(this.dashboard.filtersDefinition[0].show)
        this.getFilter0FromApi(params).then()
      if(this.dashboard.filtersDefinition[2].show)
        this.getFilter2FromApi(params).then()
    },
    filter2Changed: function(eventType){

      if(eventType === 'changed' && this.dashboard.filtersDefinition[2].multipleSelect){
        return;
      }

      // this.slicers.filters[this.dashboard.filtersDefinition[2].id] = this.dashboard.filtersDefinition[2].selected;
      // if(this.dashboard.filtersDefinition[2].selected.length == 0){
      //   delete this.slicers.filters[this.dashboard.filtersDefinition[2].id]
      // }

      this.setSlicerOnFilterChange(2);

      const params = this.convertFiltersToQueryParams();

      this.filtersChanged(null, 'filter2');

      if(this.dashboard.filtersDefinition[0].show)
        this.getFilter0FromApi(params).then()
      if(this.dashboard.filtersDefinition[1].show)
        this.getFilter1FromApi(params).then()
    },
    getFilter0FromApi: function(params){
      //not using params any more since changing that will impact other filters that have the same reference to it
      let localParams = this.convertFiltersToQueryParams();

      //-------------------------------apply preFilters of Filter0----------------------------------
      if(this.dashboard.filtersDefinition[0].preFilters && typeof this.dashboard.filtersDefinition[0].preFilters === 'object' && this.dashboard.filtersDefinition[0].preFilters !== null){
        for(let k in this.dashboard.filtersDefinition[0].preFilters){
          if(!localParams.toString().includes(k)){
            if(Array.isArray(this.dashboard.filtersDefinition[0].preFilters[k]))
              localParams.append(k, this.dashboard.filtersDefinition[0].preFilters[k].sort().join(','));
            else
              localParams.append(k, this.dashboard.filtersDefinition[0].preFilters[k]);
          }
        }
      }
      //--------------------------------------------------------------------------------------------


      this.injectGlobalFilter(localParams);
      localParams.delete(this.dashboard.filtersDefinition[0].id);

      return new Promise((resolve, reject) => {

        //should pass filters to the endpoint
        if(this.dashboard.filtersDefinition[0].reportId === "account-filter-customfield"){
          if(this.dashboard.filtersDefinition[0].hasOwnProperty("filter_custom_field")){
            localParams.append("filter_custom_field", this.dashboard.filtersDefinition[0].filter_custom_field);
          }
        }
        axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/' + this.dashboard.filtersDefinition[0].reportId  , method: 'GET', params: localParams })
        .then(resp => {
            //this.slicers.programs = resp.data;
            this.dashboard.filtersDefinition[0].list = resp.data;
            resolve();
        })
        .catch(err => {
            reject();
            console.log(err);
        })

      });
    },
    getFilter1FromApi: function(params){
      //not using params any more since changing that will impact other filters that have the same reference to it
      let localParams = this.convertFiltersToQueryParams();

      //-------------------------------apply preFilters of Filter1----------------------------------
      if(this.dashboard.filtersDefinition[1].preFilters && typeof this.dashboard.filtersDefinition[1].preFilters === 'object' && this.dashboard.filtersDefinition[1].preFilters !== null){
        for(let k in this.dashboard.filtersDefinition[1].preFilters){
          if(!localParams.toString().includes(k)){
            if(Array.isArray(this.dashboard.filtersDefinition[1].preFilters[k]))
              localParams.append(k, this.dashboard.filtersDefinition[1].preFilters[k].sort().join(','));
            else
              localParams.append(k, this.dashboard.filtersDefinition[1].preFilters[k]);
          }
        }
      }
      //--------------------------------------------------------------------------------------------


      this.injectGlobalFilter(localParams);
      localParams.delete(this.dashboard.filtersDefinition[1].id);

      return new Promise((resolve, reject) => {
        //should pass filters to the endpoint
        if(this.dashboard.filtersDefinition[1].reportId === "account-filter-customfield"){
          if(this.dashboard.filtersDefinition[1].hasOwnProperty("filter_custom_field")){
            localParams.append("filter_custom_field", this.dashboard.filtersDefinition[1].filter_custom_field);
          }
        }

        axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/' + this.dashboard.filtersDefinition[1].reportId  , method: 'GET', params: localParams })
        .then(resp => {
            //this.slicers.apps = resp.data;
            this.dashboard.filtersDefinition[1].list = resp.data;
            resolve();
        })
        .catch(err => {
            reject();
            console.log(err);
        })

      });
    },
    getFilter2FromApi: function(params){
      //not using params any more since changing that will impact other filters that have the same reference to it
      let localParams = this.convertFiltersToQueryParams();

      //-------------------------------apply preFilters of Filter2----------------------------------
      if(this.dashboard.filtersDefinition[2].preFilters && typeof this.dashboard.filtersDefinition[2].preFilters === 'object' && this.dashboard.filtersDefinition[2].preFilters !== null){
        for(let k in this.dashboard.filtersDefinition[2].preFilters){
          if(!localParams.toString().includes(k)){
            if(Array.isArray(this.dashboard.filtersDefinition[2].preFilters[k]))
              localParams.append(k, this.dashboard.filtersDefinition[2].preFilters[k].sort().join(','));
            else
              localParams.append(k, this.dashboard.filtersDefinition[2].preFilters[k]);
          }
        }
      }
      //--------------------------------------------------------------------------------------------


      this.injectGlobalFilter(localParams);
      localParams.delete(this.dashboard.filtersDefinition[2].id);

      return new Promise((resolve, reject) => {
        //should pass filters to the endpoint
        if(this.dashboard.filtersDefinition[2].reportId === "account-filter-customfield"){
          if(this.dashboard.filtersDefinition[2].hasOwnProperty("filter_custom_field")){
            localParams.append("filter_custom_field", this.dashboard.filtersDefinition[2].filter_custom_field);
          }
        }

        axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/' + this.dashboard.filtersDefinition[2].reportId  , method: 'GET', params: localParams })
        .then(resp => {
            //this.slicers.goals = resp.data;
            this.dashboard.filtersDefinition[2].list = resp.data;
            resolve();
        })
        .catch(err => {
            reject();
            console.log(err);
        })

      });
    },
    formatDate: function(current_datetime){
      let year = current_datetime.getFullYear();
      let month = current_datetime.getMonth()+1;
      let dt = current_datetime.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }

      return year+'-' + month + '-'+dt;
    },
    themeChanged: function(){
      //this.layout.chartOptions.colors = this.selectedTheme.split(',');
      this.dashboard.layout.rows.forEach(rowObj => {
          rowObj.cells.forEach(cell => {
            if(cell.chartOptions){
              cell.chartOptions.colors = this.selectedTheme.split(',');
              if(cell.chartOptions.colorAxis){
                cell.chartOptions.colorAxis.colors = cell.chartOptions.colors
              }
            }
          })
      })
    },
    dashboardChanged: async function() {

      try{
        this.setUserAccountToPortalState("selectedDashboard", this.selectedDashboard);


        this.contacts.show = false;
        this.events.show = false;
        if(!this.inPreviewMode){
          const resp = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/dashboards/'  + this.selectedDashboard  , method: 'GET' })
          this.dashboardDDL = resp.data;
          this.dashboard = this.dashboardDDL.definition;
          delete this.dashboard.selectedEventsFieldsTemp;

          this.designerDDL = this.dashboardDDL;
          //navigator.clipboard.writeText(JSON.stringify(this.designerDDL)).then();


        }
        else{
          this.dashboard = this.designerDDL.definition;
        }

        if(this.dashboard.preferences){
          //alert(JSON.stringify(this.dashboard.preferences))
          this.showOptions = this.dashboard.preferences.dashboards;
          this.slicers.distinct = this.dashboard.preferences.distinct;
          this.selectedTheme = this.dashboard.preferences.chartColors;
          this.slicers.isSeed = this.dashboard.preferences.seed;
          
          if(this.dashboardDDL.author.trim().toLowerCase() == this.$store.getters.user.email.trim().toLowerCase()){
            this.canClone = true;
            this.canGeneratePDF = true;
          } else {
            this.canClone = this.dashboard.preferences.canClone;
            this.canGeneratePDF = this.dashboard.preferences.canGeneratePDF;
          }

        } else {
          this.showOptions = true;
          this.slicers.distinct = true;
          this.selectedTheme = '#0f1f38,#8e7970,#f55449,#1b4b5a';
          this.slicers.isSeed = false;
          this.canClone = true;
          this.canGeneratePDF = true;
        }

        this.slicers.filtersId = [];
        if(this.dashboard.filtersDefinition[0].show){
          this.slicers.filtersId.push(this.dashboard.filtersDefinition[0].id);
        }
        if(this.dashboard.filtersDefinition[1].show){
          this.slicers.filtersId.push(this.dashboard.filtersDefinition[1].id);
        }
        if(this.dashboard.filtersDefinition[2].show){
          this.slicers.filtersId.push(this.dashboard.filtersDefinition[2].id);
        }

        //console.log('this.slicers.filtersId: ', this.slicers.filtersId);

        //console.log('this.dashboard: ', JSON.stringify(this.dashboard));

        if(this.dashboard.audience === "admins" || this.dashboard.audience === "parents"){

          this.showContactsAndEvents = false;
        }
        else{
          this.showContactsAndEvents = true;
        }

        this.slicers.filters = {};
        this.slicers.selectionFilter = {};
        this.slicers.selectedApps = [];
        this.slicers.selectedGoals = [];
        this.slicers.selectedPrograms = [];

        if(this.dashboard.datePicker.type === 'range')
          await this.dateRangeChanged();
        else if(this.dashboard.datePicker.type === 'day')
          await this.dayChanged();

        await this.getCustomFieldsFromApi();

        if(this.dashboard.filtersDefinition[0].selected && this.dashboard.filtersDefinition[0].selected.length > 0){
          this.slicers.filters[this.dashboard.filtersDefinition[0].id] = this.dashboard.filtersDefinition[0].selected;
          this.filter0Changed();
        }

        if(this.dashboard.filtersDefinition[1].selected && this.dashboard.filtersDefinition[1].selected.length > 0){
          this.slicers.filters[this.dashboard.filtersDefinition[1].id] = this.dashboard.filtersDefinition[1].selected;
          this.filter1Changed();
        }

        if(this.dashboard.filtersDefinition[2].selected && this.dashboard.filtersDefinition[2].selected.length > 0){
          this.slicers.filters[this.dashboard.filtersDefinition[2].id] = this.dashboard.filtersDefinition[2].selected;
          this.filter2Changed();
        }
      }
      catch(err){
        //alert(JSON.stringify(err));
        console.log(err);
      }

    },
    setSelectionFilter: function(cell_id, row, col) {
      let rowDIM = null;
      let rowID = 0;
      let colDIM = null;
      let colID = 0;
      let groupby_custom_field = 'z_communityname';
      let eventoption_prop = 'os';

      //console.log(' cell_id: ', cell_id, ' row: ', row, ' col: ', col);
      this.dashboard.layout.rows.forEach(rowObj => {
          rowObj.cells.forEach(cell => {
            if(cell.id == cell_id && !cell.hasOwnProperty('kpi1')){

              this.slicers.selectedCell = cell;

              //console.log(cell.chartData)
              if(cell && cell.reportId.includes('-score')){
                  colDIM = 'score';
              }

              if(cell.selectedEventsFields && cell.selectedEventsFields.length > 0){
                this.dashboard.selectedEventsFieldsTemp = this.dashboard.selectedEventsFields;
                this.dashboard.selectedEventsFields = cell.selectedEventsFields;
              }

              if(cell.hasOwnProperty('groupby_custom_field')){
                groupby_custom_field = cell.groupby_custom_field;
              }

              if(cell.hasOwnProperty('eventoption_prop')){
                eventoption_prop = cell.eventoption_prop;
              }

              if(row && row > 0){
                rowDIM = cell.rowDIM;
                rowID = cell.chartData[row][0].v;
              }
              if(col > 0 && colDIM != 'score'){
                colID = cell.chartData[0][col].id;
                colDIM = cell.colDIM;
              }
            }
          })
      });

      if(rowDIM){
        //console.log(rowDIM, ': ', rowID)
        this.slicers.selectionFilter[rowDIM] = rowID;
        if(rowDIM === 'custom_Field'){
          this.slicers.selectionFilter['groupby_custom_field'] = groupby_custom_field;
        }

        if(rowDIM === 'eventoption_prop'){
          this.slicers.selectionFilter['eventoption_prop'] = eventoption_prop + '__' +  rowID;
        }
      }

      if(colDIM){
        //console.log(colDIM, ': ',colID)
        this.slicers.selectionFilter[colDIM] = colID;
      }

      console.log(this.slicers.selectionFilter);
    },
    clickableTableClicked: async function(selectedCell, selectionFilter){
      //alert(JSON.stringify(selectedCell));
      //alert(JSON.stringify(selectionFilter));
      this.selectedKPI.cell = null;
    },
    calculatedTableClicked: async function(selectedCell, selectionFilter){
      //alert(JSON.stringify(selectionFilter));
      this.selectedKPI.cell = null;

      if(selectionFilter){
        this.slicers.selectionFilter = selectionFilter;
        this.slicers.selectedCell = selectedCell;
      }
      else{
        this.slicers.selectionFilter = {};
        this.slicers.selectedCell = null;
      }


      this.getContactsFromApi().then().catch(err => {
        console.log('getContactsFromApi: ', err)
      });

      this.getEventsFromApi().then().catch(err => {
        console.log('getEventsFromApi: ', err)
      });

      this.dashboard.layout.rows.forEach(row => {
        row.cells.forEach(async (cell) => {
          await delay(200);

          if(selectedCell.id != cell.id){
            try {
              const resp = await this.getCellDataFromApi(cell);
              cell.chartData = resp;
            } catch (error) {
              alert(error);
            }
          }

        })
      })

    },
    filtersChanged: function(selections, calledBy = null){
      if(this.dashboard.selectedEventsFieldsTemp){
        this.dashboard.selectedEventsFields = this.dashboard.selectedEventsFieldsTemp;
      }
      this.selectedKPI.daterangeName = null;
      this.selectedKPI.cell = null;

      //loop through layout rows and cells and ajax call to /api/bi/v1/report/data/reportId?filters
      //and populate cell.chartData

      if(selections && selections.length > 0){
        //console.log(selections)
        const cell_id = selections[0].key.replace("gchart","");
        let row = selections[0].selection[0].row;
        if(row != null){
          row++;
        }
        this.setSelectionFilter(cell_id, row, selections[0].selection[0].column)
      }
      else{
        this.slicers.selectionFilter = {};
        this.slicers.selectedCell = null;
        //console.log(this.slicers.selectionFilter);
      }

      this.getContactsFromApi().then().catch(err => {
        console.log('getContactsFromApi: ', err)
      });

      this.getEventsFromApi().then().catch(err => {
        console.log('getEventsFromApi: ', err)
      });

      if(calledBy == 'isSeed' || calledBy == 'isGurl'){
        const params = this.convertFiltersToQueryParams();
        if(this.dashboard.filtersDefinition[0].show)
        this.getFilter0FromApi(params).then()
        if(this.dashboard.filtersDefinition[1].show)
          this.getFilter1FromApi(params).then()
        if(this.dashboard.filtersDefinition[2].show)
          this.getFilter2FromApi(params).then()
      }

      let i=0;
      this.dashboard.layout.rows.forEach(row => {
        row.cells.forEach(async (cell) => {
          i++
          await delay(i * 200);
          if(selections && selections.length > 0){

            if(selections[0].key !== "gchart" + cell.id && !cell.hasOwnProperty('kpi1')){

              try {
                const resp = await this.getCellDataFromApi(cell);
                cell.chartData = resp;
                this.getChartTotal(cell);
                this.getChartGroupTotal(cell);
              } catch (error) {
                alert(JSON.stringify(error));
              }

            }
          }
          else{

            try {
              const resp = await this.getCellDataFromApi(cell, calledBy);
              if(!cell.hasOwnProperty('kpi1')){
                cell.chartData = resp;
                this.getChartTotal(cell);
                this.getChartGroupTotal(cell);
              }
            } catch (error) {
              //alert('error in calling cell id: ' + cell.id);
              cell.loading = false;
            }

          }

        })
      })
    },
    getChartTotal: function(cell){
      if(cell.chartData && cell.titleTemplate && cell.titleTemplate.includes('##chart-total##')){
        //console.log('replacing ##chart-total##');
        let total = 0;
        for(let i=0; i < cell.chartData.length; i++){
          if(i == 0)
            continue;
          for(let j=0; j < cell.chartData[i].length; j++){
            if(j == 0)
              continue;
            total += cell.chartData[i][j];
          }
        }

        cell.title = cell.titleTemplate.replace('##chart-total##', total);
      }
    },
    getChartGroupTotal: function(cell){
      if(cell.chartData && cell.titleTemplate && cell.titleTemplate.includes('##chart-group-total##')){
        //console.log('replacing ##chart-total##');
        let total = 0;
        for(let i=0; i < cell.chartData.length; i++){
          if(i == 0)
            continue;
          for(let j=0; j < cell.chartData[i].length; j++){
            if(j == 0)
              continue;
            total += cell.chartData[i][j];
          }
        }

        cell.title = cell.titleTemplate.replace('##chart-group-total##', total);
      }
    },
    convertFiltersToQueryParams: function(cell) {

      const dimentionIds = ['program_ID', 'campaign_ID', 'serviceType_ID', 'goal_ID', 'event_ID', 'inbound_ID', 'inboundDetail_ID', 'outbound_ID', 'outboundSchedule_ID', 'forminput_id', 'city', 'state', 'gender', 'analytic_str_1', 'analytic_str_2', 'formResponse', 'eventDrivenBy', 'ps_listid', 'groupby_custom_field', 'browser', 'device','linkname','eventoption_prop']

      try {

        const params = new URLSearchParams();
        for (const dimentionId of dimentionIds){

          //if(!cell || !cell.validFilters || cell.validFilters.includes(dimentionId)){
            if(this.slicers && this.slicers.selectionFilter && this.slicers.selectionFilter.hasOwnProperty(dimentionId)){
              //cell.preFilterHasPriority == true
              if(cell && cell.preFilterHasPriority && cell.preFilters.hasOwnProperty(dimentionId)){
                try {

                  // if(cell && cell.id == 4 ){
                  //   console.log('cell.preFilters', cell.preFilters);
                  //   console.log('dimentionId', dimentionId);
                  //   console.log('cell.preFilters[dimentionId]', cell.preFilters[dimentionId])
                  //   console.log('cell.preFilters[dimentionId].includes(this.slicers.selectionFilter[dimentionId].toString())', cell.preFilters[dimentionId.toLowerCase()].includes(this.slicers.selectionFilter[dimentionId].toString()));
                  // }
                  if(cell.preFilters && cell.preFilters[dimentionId] && cell.preFilters[dimentionId].includes(this.slicers.selectionFilter[dimentionId].toString())){
                    if(dimentionId === 'groupby_custom_field'){
                      params.append('groupby_custom_field_where', this.slicers.selectionFilter[dimentionId]);
                      params.append('custom_Field', this.slicers.selectionFilter['custom_Field']);
                    }
                    else
                     params.append(dimentionId, this.slicers.selectionFilter[dimentionId]);
                  }
                } catch (error) {
                    params.append(dimentionId, this.slicers.selectionFilter[dimentionId]);
                }

              }
              else{
                  if(dimentionId === 'groupby_custom_field'){
                    params.append('groupby_custom_field_where', this.slicers.selectionFilter[dimentionId]);
                    params.append('custom_Field', this.slicers.selectionFilter['custom_Field']);

                  } else if (dimentionId === 'eventoption_prop'){
                    params.append('prop_where', this.slicers.selectionFilter['eventoption_prop'].split('__')[0]);
                    params.append('prop_value', this.slicers.selectionFilter['eventoption_prop'].split('__')[1]);

                  }
                  else
                    params.append(dimentionId, this.slicers.selectionFilter[dimentionId]);
              }

            }
            else if(this.slicers && this.slicers.filters && this.slicers.filters[dimentionId] && this.slicers.filters[dimentionId][0] != 0){

              let included = true;

              if(this.slicers.filtersId.includes(dimentionId)){
                const result = this.dashboard.filtersDefinition.find( item => item.id == dimentionId && item.useNotIn);
                if(result){
                  included = false;
                  if(dimentionId === 'groupby_custom_field'){
                    params.append('groupby_custom_field_where', this.slicers.filters[dimentionId]);
                    params.append('custom_Field', '-1,' + this.slicers.filters['custom_Field'].sort().join(','));
                  }
                  else{
                    params.append(dimentionId, '-1,' + this.slicers.filters[dimentionId].sort().join(','));
                  }
                }
              }

              if(included){
                if(dimentionId === 'groupby_custom_field'){
                  params.append('groupby_custom_field_where', this.slicers.filters[dimentionId]);
                  params.append('custom_Field', this.slicers.filters['custom_Field'].sort().join(','));
                }
                else{
                  params.append(dimentionId, this.slicers.filters[dimentionId].sort().join(','));
                }
              }
            }
          //}

        }

        if(this.slicers && this.slicers.filters && this.slicers.filters['##filter_custom_field##'] && this.slicers.filters['custom_Field'] && this.slicers.filters['custom_Field'][0]){
            params.append('groupby_custom_field_where', this.slicers.filters['##filter_custom_field##']);
            params.append('custom_Field', this.slicers.filters['custom_Field'].sort().join(','));
        }

        if(this.slicers.selectionFilter && this.slicers.selectionFilter.date_range){
          //console.log('selection filter: ', this.slicers.selectionFilter)

          let year, month, day;
          [year, month, day] = this.slicers.selectionFilter.date_range.replace('Date(','').replace(')','').split(',');

          var start = new Date(year, month, day);
          var end = new Date(year, month, day);
          end.setDate(end.getDate() + 1);

          params.append('date_range', this.formatDate(start) + ',' + this.formatDate(end));
        }
        else if(this.slicers.selectionFilter && this.slicers.selectionFilter.month){
          params.append('date_range',  this.slicers.selectionFilter.month + '-01,' + this.dateFunc.getFirstOfNextMonth(this.slicers.selectionFilter.month)); //
        }
        else if(this.slicers.filters && this.slicers.filters.date_range.length > 0){
          params.append('date_range', this.slicers.filters.date_range.join(','));
        }

        if(this.dashboard.datePicker.type === 'day' && this.slicers.filters && this.slicers.filters.date){
          //console.log(this.slicers.filters.date)
          params.append('date', this.slicers.filters.date);
        }

        params.append('tz_offset', new Date().getTimezoneOffset());

        if(this.slicers.distinct)
          params.append('distinct', 'distinct');

        if(this.slicers.isSeed)
          params.append('isseed', 1);
        else
          params.append('isseed', 0);

        if(this.slicers.isGurl)
          params.append('isgurl', 1);
        else
          params.append('isgurl', 0);

        // if(this.dashboard.filterWhereClause)
        //   params.append('filterWhereClause', this.dashboard.filterWhereClause);

        try{
          if(cell && cell.reportId && ( cell.reportId.startsWith('account-groupby-customfield-count') || cell.reportId.startsWith('account-groupby-customfield-sum'))){

            if(params.toString().includes('groupby_custom_field')){
              params.delete('groupby_custom_field')
            }
            params.append('groupby_custom_field', cell.groupby_custom_field);

            if(!params.toString().includes('sum_custom_field')){
              params.delete('sum_custom_field')
            }
            params.append('sum_custom_field', cell.sum_custom_field);
          }

          if(cell && cell.reportId && cell.reportId.includes('-prop')){
            //alert(cell.groupby_prop_field);
            params.append('prop', cell.groupby_prop_field); //cell.groupby_eventoption_field
          }
        }
        catch(err){
          alert(JSON.stringify(err));
        }

        if(this.slicers.selectionFilter.hasOwnProperty('score')){
            params.append('score', '');
        }

        

        return params;

      } catch (error) {
        console.log(error);
        return null;
      }
    },
    createKpiQueryParams: function(daterangeName, cell, usePrefilters2){

      let impactByFilter = false;
      let filtersToConvert = [];

      if(cell.impactByFilters && cell.impactByFilters.length > 0 ){
        if(cell.impactByFilters.includes(0)){
          filtersToConvert.push(this.dashboard.filtersDefinition[0].id)
          impactByFilter = true;
        }
        if(cell.impactByFilters.includes(1)){
          filtersToConvert.push(this.dashboard.filtersDefinition[1].id)
          impactByFilter = true;
        }
        if(cell.impactByFilters.includes(2)){
          filtersToConvert.push(this.dashboard.filtersDefinition[2].id)
          impactByFilter = true;
        }
      }

      const params = new URLSearchParams();

      if(!usePrefilters2){
        for(let k in cell.preFilters){
            if(k === 'groupby_custom_field_where'){
              params.append('groupby_custom_field_where', cell.preFilters[k]);
            }
            else if(k === 'custom_Field'){
              params.append('custom_Field', cell.preFilters[k].sort().join(','));
            }
            else{
              params.append(k, cell.preFilters[k].sort().join(','));
            }
        }
      }
      else{
        for(let k in cell.preFilters2){
          if(k === 'groupby_custom_field_where'){
              params.append('groupby_custom_field_where', cell.preFilters2[k]);
            }
            else if(k === 'custom_Field'){
              params.append('custom_Field', cell.preFilters2[k].sort().join(','));
            }
            else{
              params.append(k, cell.preFilters2[k].sort().join(','));
            }
        }
      }

      //if(cell.hasOwnProperty('useMasterDateRange') && cell.useMasterDateRange === true)
      if(this.templateName(cell) === "master-date-range" || cell.templateName === "ratio-with-master-date-range")
        params.append('date_range', this.slicers.filters.date_range.join(','));
      else
        params.append('date_range', this.dateFunc.getRange(this.dateFunc.rangeCodes[daterangeName]));


      params.append('tz_offset', new Date().getTimezoneOffset());

      if(this.slicers.isSeed)
        params.append('isseed', 1);
      else
        params.append('isseed', 0);


      if(this.slicers.isGurl)
          params.append('isgurl', 1);
      else
          params.append('isgurl', 0);

      if(impactByFilter){
        for (const dimentionId of filtersToConvert){

          if(this.slicers && this.slicers.filters && this.slicers.filters[dimentionId] && this.slicers.filters[dimentionId][0] != 0){

            let included = true;

            if(this.slicers.filtersId.includes(dimentionId)){
              const result = this.dashboard.filtersDefinition.find( item => item.id == dimentionId && item.useNotIn);
              if(result){
                included = false;
                params.append(dimentionId, '-1,' + this.slicers.filters[dimentionId].sort().join(','));
              }
            }

            if(included){
              params.append(dimentionId, this.slicers.filters[dimentionId].sort().join(','));
            }

          }
        }
      }

      if(cell.reportId === 'account-sum-scores'){
        params.append('score', '');
      }

      return params;
    },
    injectGlobalFilter: function(params){
      if(this.dashboard.filterWhereClause && typeof this.dashboard.filterWhereClause === 'object' && this.dashboard.filterWhereClause !== null){
        for(let k in this.dashboard.filterWhereClause){
          if(!params.toString().includes(k)){
            if(Array.isArray(this.dashboard.filterWhereClause[k]))
              params.append(k, this.dashboard.filterWhereClause[k].sort().join(','));
            else
              params.append(k, this.dashboard.filterWhereClause[k]);
          }
        }
      }
    },
    getCellDataFromApi: async function(cell, calledBy = null){
      cell.loading = true;

      const paramsCopy = this.convertFiltersToQueryParams(cell)

      //------------apply selected cell prefilters to query--------------------------------------------
      this.applySelectedCellPreFiltersToParams(paramsCopy);
      //-----------------------------------------------------------------------------------------------------

      if(cell.resultLimit){
        paramsCopy.append('limit', cell.resultLimit);
      }

      if(cell.preFilters && Object.keys(cell.preFilters).length > 0){

        for(let k in cell.preFilters){
          if(paramsCopy){
              if(!paramsCopy.toString().includes(k)){
                if(Array.isArray(cell.preFilters[k]))
                  paramsCopy.append(k, cell.preFilters[k].sort().join(','));
                else
                  paramsCopy.append(k, cell.preFilters[k]);
              }

          }
        }

      }

      this.injectGlobalFilter(paramsCopy);

      if(cell.hasOwnProperty('kpi1')){

        //if((cell.impactByFilters && cell.impactByFilters.length > 0  && calledBy.startsWith('filter')) || (cell.kpi1 == 0 && cell.kpi2 == 0) || (cell.hasOwnProperty('useMasterDateRange') && cell.useMasterDateRange === true)){
        if(calledBy == 'isSeed' || (cell.impactByFilters && cell.impactByFilters.length > 0  && calledBy && calledBy.startsWith('filter')) || (cell.kpi1 == 0 && cell.kpi2 == 0) || this.templateName(cell) === "master-date-range"  || this.templateName(cell) === "ratio-with-master-date-range"){
          const params = this.createKpiQueryParams(cell.kpi1DateRange, cell);

          try {
            this.injectGlobalFilter(params);

            const result = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/'  + cell.reportId  , method: 'GET', params: params });

            if(result.data[0].hasOwnProperty('contactscount')){
              cell.kpi1 = Number(result.data[0].contactscount);
            }
            else if(result.data[0].hasOwnProperty('eventscount')){
              cell.kpi1 = Number(result.data[0].eventscount);
            }
            else if(result.data[0].hasOwnProperty('totalscores')){
              cell.kpi1 = Number(result.data[0].totalscores);
            }

          } catch (error) {
            cell.loading = false;
            throw error;
          }

          //if(!cell.hasOwnProperty('useMasterDateRange') || cell.useMasterDateRange === false)
          if(this.templateName(cell) === "internal-date-range"  || this.templateName(cell) === "ratio-with-master-date-range")
          {
            let params2= null;
            if(this.templateName(cell) === "ratio-with-master-date-range")
              params2 = this.createKpiQueryParams(cell.kpi2DateRange, cell, true);
            else
              params2 = this.createKpiQueryParams(cell.kpi2DateRange, cell);

            try {
              this.injectGlobalFilter(params2);

              const result = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/'  + cell.reportId  , method: 'GET', params: params2 });

              if(result.data[0].hasOwnProperty('contactscount')){
                cell.kpi2 = Number(result.data[0].contactscount);
              }
              else if(result.data[0].hasOwnProperty('eventscount')){
                cell.kpi2 = Number(result.data[0].eventscount);
              }
              else if(result.data[0].hasOwnProperty('totalscores')){
                cell.kpi1 = Number(result.data[0].totalscores);
              }

            } catch (error) {
              cell.loading = false;
              throw error;
            }

          }
        }

        cell.loading = false;
        return null;
      }
      else{
        try {
          const result = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/'  + cell.reportId  , method: 'GET', params: paramsCopy });

          try {
            //---------------Exclude unwanted dimentions----------------------
            if(cell.hasOwnProperty('excludedRows') && cell.excludedRows.length > 0){
              let filteredData = result.data.filter(row => {
                if(row[0].hasOwnProperty('v') && row[0].hasOwnProperty('f') && (cell.excludedRows.includes(row[0].v) || cell.excludedRows.includes(row[0].f) )){
                  return false;
                }
                else{
                  return true
                }

              })

              result.data = filteredData;
            }
            //---------------Replacing labels in result.data------------------
            if((cell.hasOwnProperty('labels') || this.dashboard.hasOwnProperty('labels')) && result.data.length >0 ){

              let isVertical = true;

              let labels = [];
              if(this.dashboard.hasOwnProperty('labels') && this.dashboard.labels.length > 0 ){
                labels = this.dashboard.labels;
              }
              if(cell.hasOwnProperty('labels') && cell.labels.length > 0 ){
                labels = cell.labels;
              }

              if(labels && labels.length > 0){

                for(const item of labels){
                  for(let column of result.data[0]){
                    if(column.hasOwnProperty('id') && item.id == column.id){
                      column.label = item.label;
                      isVertical = false;
                      break;
                    }
                  }
                }

                if(isVertical){
                  for(const item of labels){
                    for(let row of result.data){
                      if(row[0].hasOwnProperty('v') && item.id == row[0].v){
                        row[0].f = item.label;
                        break;
                      }
                    }
                  }
                }
              }
            }
            //-------------------------------------------------------------------

          } catch (err) {
            alert(err);
          }

          cell.loading = false;
          return result.data;
        }
        catch (error) {
          cell.loading = false;
          throw error;
        }
      }
    },
    downloadContacts: async function(obj, isScheduler){
      if(!this.contacts.show)
        return;

      let searchClause = '';
      if(this.contacts.search){
        searchClause = " and c." + this.contacts.searchField + " ilike '%" + this.contacts.search + "%' ";
      }

      const {
          sortBy,
          sortDesc,
          page,
          itemsPerPage
      } = this.contacts.pagination;

      let orderby = '  c.city desc';
      if(sortBy && sortBy.length > 0){

        if(this.contacts.customFieldSelections.includes(sortBy[0])){
          orderby = ` ${sortBy[0]} ${(sortDesc[0] ? 'desc' : 'asc')}`
        } else{
          orderby = ` c.${sortBy[0]} ${(sortDesc[0] ? 'desc' : 'asc')}`
        }
      }

      let querySelectArray = this.dashboard.selectedContactsStandardFields.map(val => {
        return ' c.' + val;
      })


      let paramsCopy = null;

      if(this.selectedKPI.cell != null){
        if(this.selectedKPI.usePrefilters2){
          paramsCopy = this.createKpiQueryParams(this.selectedKPI.daterangeName, this.selectedKPI.cell, true);
        }
        else
          paramsCopy = this.createKpiQueryParams(this.selectedKPI.daterangeName, this.selectedKPI.cell);

      }
      else
        paramsCopy = this.convertFiltersToQueryParams();

      paramsCopy.append('orderby', orderby);
      paramsCopy.append('select', querySelectArray.join(','));
      if(this.contacts.customFieldSelections && this.contacts.customFieldSelections.length > 0){
        paramsCopy.append('selectCustom', this.contacts.customFieldSelections.join(','));
      }

      if(this.contacts.search){
        paramsCopy.append('search', searchClause);
      }

      //------------apply selected cell prefilters to query--------------------------------------------
      this.applySelectedCellPreFiltersToParams(paramsCopy);
      //-----------------------------------------------------------------------------------------------------

      //{"fileName":"test2.csv_","delimiter":",","multipleFiles":false,"zipped":false,"addQuotes":false,"overwrite":false}
      if(!isScheduler){
        paramsCopy.append('downloadName', obj.fileName);
        paramsCopy.append('downloadDelimiter', obj.delimiter);
        if(Boolean(obj.multipleFiles))
          paramsCopy.append('downloadMultiple', obj.multipleFiles);
        if(Boolean(obj.zipped))
          paramsCopy.append('downloadZip', obj.zipped);
        if(Boolean(obj.addQuotes))
          paramsCopy.append('downloadQuotes', obj.addQuotes);
        if(Boolean(obj.overwrite))
          paramsCopy.append('downloadOverwrite', obj.overwrite);
      }

      try {
        this.injectGlobalFilter(paramsCopy);

        if(!isScheduler){
          const resp = await  axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/account-download-contacts'  , method: 'GET', params: paramsCopy })
          alert(resp.data.message);
        }
        else{
          this.contacts.cronInstruction = {
            url: '/api/bi/v1/reports/account-download-contacts?' + paramsCopy.toString()
          }

          console.log(this.contacts.cronInstruction);
        }


      } catch (error) {
          if(error && error.response && error.response.status && 403 === error.response.status)
            alert('User has no permission to download Contact List');
      }
    },
    getContactsFromApi: async function(noCount) {
      if(!this.contacts.show)
        return;

      let searchClause = '';
      if(this.contacts.search){
        searchClause = " and c." + this.contacts.searchField + " ilike '%" + this.contacts.search + "%' ";
      }

      this.contacts.loading = true;
      if(!noCount)
        this.contacts.pagination.page=1;

      const {
          sortBy,
          sortDesc,
          page,
          itemsPerPage
      } = this.contacts.pagination;

      let orderby = '  c.city desc';
      if(sortBy && sortBy.length > 0){
        if(this.contacts.customFieldSelections.includes(sortBy[0])){
          orderby = ` ${sortBy[0]} ${(sortDesc[0] ? 'desc' : 'asc')}`
        } else{
          orderby = ` c.${sortBy[0]} ${(sortDesc[0] ? 'desc' : 'asc')}`
        }

      }

      let final_selectedContactsStandardFields = null;
      if(this.dashboard.selectedContactsStandardFields.includes('id'))
        final_selectedContactsStandardFields = this.dashboard.selectedContactsStandardFields;
      else
        final_selectedContactsStandardFields = ['id', ...this.dashboard.selectedContactsStandardFields];


      let querySelectArray = final_selectedContactsStandardFields.map(val => {
        return ' c.' + val;
      })

      let paramsCopy = null;

      if(this.selectedKPI.cell != null){
        if(this.selectedKPI.usePrefilters2){
          paramsCopy = this.createKpiQueryParams(this.selectedKPI.daterangeName, this.selectedKPI.cell, true);
        }
        else
          paramsCopy = this.createKpiQueryParams(this.selectedKPI.daterangeName, this.selectedKPI.cell);
      }
      else
        paramsCopy = this.convertFiltersToQueryParams();

      paramsCopy.append('limit', itemsPerPage);
      paramsCopy.append('offset', (page - 1) * itemsPerPage);
      paramsCopy.append('orderby', orderby);
      paramsCopy.append('select', querySelectArray.join(','));
      if(this.contacts.customFieldSelections && this.contacts.customFieldSelections.length > 0){
        paramsCopy.append('selectCustom', this.contacts.customFieldSelections.join(','));
      }

      if(this.contacts.search){
        paramsCopy.append('search', searchClause);
      }

      //------------apply selected cell prefilters to query--------------------------------------------
      this.applySelectedCellPreFiltersToParams(paramsCopy);
      //-----------------------------------------------------------------------------------------------------

      let count = {};
      let total = 0;
      try {
        this.injectGlobalFilter(paramsCopy);
        if(!noCount){
          count = await  axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/account-count-contacts'  , method: 'GET', params: paramsCopy })
          //console.log(count.data[0].contactscount);
        }
        const resp = await  axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/account-list-contacts'  , method: 'GET', params: paramsCopy })

        //console.log(resp.data)

        let items = resp.data;
        if(!noCount)
          total = Number(count.data[0].contactscount);
        else
          total = this.contacts.count;


        this.contacts.loading = false

        this.contacts.list = resp.data;
        this.contacts.count = total;

      } catch (error) {
        console.log(error);
      }

    },
    downloadEvents: async function(obj, isScheduler) {
      if(!this.events.show)
        return;

      let searchClause = '';
      if(this.events.search){
        searchClause = " and et." + this.events.searchField + " ilike '%" + this.events.search + "%' ";
      }

      const {
          sortBy,
          sortDesc,
          page,
          itemsPerPage
      } = this.events.pagination;

      let orderby = '  et.eventtimestamp desc';
      if(sortBy && sortBy.length > 0){
        orderby = ` et.${sortBy[0]} ${(sortDesc[0] ? 'desc' : 'asc')}`
      }

      let querySelectArray = this.dashboard.selectedEventsFields.map(val => {
        return ' et.' + val;
      })

      let paramsCopy = null;

      if(this.selectedKPI.cell != null){
        if(this.selectedKPI.usePrefilters2){
          paramsCopy = this.createKpiQueryParams(this.selectedKPI.daterangeName, this.selectedKPI.cell, true);
        }
        else
          paramsCopy = this.createKpiQueryParams(this.selectedKPI.daterangeName, this.selectedKPI.cell);

      }
      else
        paramsCopy = this.convertFiltersToQueryParams();

      paramsCopy.append('orderby', orderby);
      paramsCopy.append('select', querySelectArray.join(','));
      if(this.events.search){
        paramsCopy.append('search', searchClause);
      }

      //------------apply selected cell prefilters to query--------------------------------------------
      this.applySelectedCellPreFiltersToParams(paramsCopy);
      //-----------------------------------------------------------------------------------------------------

      //{"fileName":"test2.csv_","delimiter":",","multipleFiles":false,"zipped":false,"addQuotes":false,"overwrite":false}
      if(!isScheduler){
        paramsCopy.append('downloadName', obj.fileName);
        paramsCopy.append('downloadDelimiter', obj.delimiter);
        if(Boolean(obj.multipleFiles))
          paramsCopy.append('downloadMultiple', obj.multipleFiles);
        if(Boolean(obj.zipped))
          paramsCopy.append('downloadZip', obj.zipped);
        if(Boolean(obj.addQuotes))
          paramsCopy.append('downloadQuotes', obj.addQuotes);
        if(Boolean(obj.overwrite))
          paramsCopy.append('downloadOverwrite', obj.overwrite);
      }

      try {

        this.injectGlobalFilter(paramsCopy);
        if(!isScheduler){
          const resp = await  axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/account-download-events'  , method: 'GET', params: paramsCopy })
          alert(resp.data.message);
        }
        else{
          this.events.cronInstruction = {
            url: '/api/bi/v1/reports/account-download-events?' + paramsCopy.toString()
          }

          console.log(this.events.cronInstruction);
        }


      } catch (error) {
         if(error && error.response && error.response.status && 403 === error.response.status)
            alert('User has no permission to download Event List');
      }

    },
    getEventsFromApi: async function(noCount) {
      if(!this.events.show)
        return;

      let searchClause = '';
      if(this.events.search){
        searchClause = " and et." + this.events.searchField + " ilike '%" + this.events.search + "%' ";
      }

      this.events.loading = true;
      if(!noCount)
        this.events.pagination.page=1;

      const {
          sortBy,
          sortDesc,
          page,
          itemsPerPage
      } = this.events.pagination;

      let orderby = '  et.eventtimestamp desc';
      if(sortBy && sortBy.length > 0){
        if(sortBy[0].endsWith('_name'))
          orderby = ` et.${sortBy[0].replace('_name','_id')} ${(sortDesc[0] ? 'desc' : 'asc')}`
        else
          orderby = ` et.${sortBy[0]} ${(sortDesc[0] ? 'desc' : 'asc')}`
      }

      let final_selectedEventsFields = null;
      if(this.dashboard.selectedEventsFields.includes('contact_id'))
        final_selectedEventsFields = this.dashboard.selectedEventsFields;
      else
        final_selectedEventsFields = ['contact_id', ...this.dashboard.selectedEventsFields];

      let querySelectArray = final_selectedEventsFields.map(val => {
        return ' et.' + val;
      })

      let paramsCopy = null;

      if(this.selectedKPI.cell != null){
        if(this.selectedKPI.usePrefilters2){
          paramsCopy = this.createKpiQueryParams(this.selectedKPI.daterangeName, this.selectedKPI.cell, true);
        }
        else
          paramsCopy = this.createKpiQueryParams(this.selectedKPI.daterangeName, this.selectedKPI.cell);

      }
      else
        paramsCopy = this.convertFiltersToQueryParams();

      paramsCopy.append('limit', itemsPerPage);
      paramsCopy.append('offset', (page - 1) * itemsPerPage);
      paramsCopy.append('orderby', orderby);
      paramsCopy.append('select', querySelectArray.join(','));
      if(this.events.search){
        paramsCopy.append('search', searchClause);
      }

      //------------apply selected cell prefilters to query--------------------------------------------
      this.applySelectedCellPreFiltersToParams(paramsCopy);
      //-----------------------------------------------------------------------------------------------------

      let count = {};
      let total = 0;
      try {
        this.injectGlobalFilter(paramsCopy);
        if(!noCount){
          count = await  axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/account-count-eventtransaction'  , method: 'GET', params: paramsCopy })
          //console.log(count.data[0].eventscount);
        }
        const resp = await  axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/account-list-eventtransaction'  , method: 'GET', params: paramsCopy })

        //console.log(resp.data)

        let items = resp.data;
        if(!noCount)
          total = Number(count.data[0].eventscount);
        else
          total = this.events.count;


        this.events.loading = false

        this.events.list = resp.data;
        this.events.count = total;

      } catch (error) {
        console.log(error);
      }

    },
    getContactEventsFromApi: async function(contactId) {

      let searchClause = ` and et.contact_id =  ${contactId} `;

      let orderby = '  et.eventtimestamp desc';

      let final_selectedEventsFields = this.dashboard.selectedEventsFields;

      if(!final_selectedEventsFields.includes('eventtimestamp'))
        final_selectedEventsFields = ['eventtimestamp', ...final_selectedEventsFields];

      if(!final_selectedEventsFields.includes('servicetype_id'))
        final_selectedEventsFields = ['servicetype_id', ...final_selectedEventsFields];

      if(!final_selectedEventsFields.includes('event_id'))
        final_selectedEventsFields = ['event_id', ...final_selectedEventsFields];

      let querySelectArray = final_selectedEventsFields.map(val => {
        return ' et.' + val;
      })

      let paramsCopy = null;

      if(this.selectedKPI.cell != null){
        if(this.selectedKPI.usePrefilters2){
          paramsCopy = this.createKpiQueryParams(this.selectedKPI.daterangeName, this.selectedKPI.cell, true);
        }
        else
          paramsCopy = this.createKpiQueryParams(this.selectedKPI.daterangeName, this.selectedKPI.cell);

      }
      else
        paramsCopy = this.convertFiltersToQueryParams();

      paramsCopy.append('limit', 20);
      paramsCopy.append('offset', 0);
      paramsCopy.append('orderby', orderby);
      paramsCopy.append('select', querySelectArray.join(','));
      paramsCopy.append('search', searchClause);

      //------------apply selected cell prefilters to query--------------------------------------------
      this.applySelectedCellPreFiltersToParams(paramsCopy);
      //-----------------------------------------------------------------------------------------------------

      try {

        const resp = await  axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/account-list-eventtransaction'  , method: 'GET', params: paramsCopy })

        return resp.data;

      } catch (error) {
        console.log(error);
      }

    },
    getCustomFieldsFromApi: async function() {
      try {
        const resp = await  axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/reports/account-fields-custom'  , method: 'GET', params: null });
        this.contacts.customFields = resp.data.map(val => {
          return val.name.toLowerCase();
        })
        this.contacts.customFields = this.contacts.customFields.sort();
        //console.log('contactCustomeFields: ', this.contactCustomeFields);
        //alert(JSON.stringify(this.dashboard.selectedContactsCustomFields));
        if(this.dashboard && this.dashboard.selectedContactsCustomFields){
          this.contacts.customFieldSelections = this.dashboard.selectedContactsCustomFields;
        } else {
          this.contacts.customFieldSelections = [];
        }

        await this.getContactsFromApi(true);

      } catch (error) {
        console.log('getCustomFieldsFromApi: ', error)
      }
    },
    contactsSearchIsClicked: async function() {
      await this.getContactsFromApi(false);
    },
    eventsSearchIsClicked: async function() {
      await this.getEventsFromApi(false);
    },
    dataRangePickerChanged: function(value){
        //alert(`datePickerSelection: ${value}`);
        this.setUserAccountToPortalState("selectedDateRange", value);
      
    },
    dayPickerChanged: function(value){
        //alert(`dayPickerSelection: ${value}`);
        //this.setUserAccountToPortalState("selectedDay", value);
      
    },
    addDaysToDate: function(dateStr, days) {
      const date = new Date(`${dateStr}T00:00:00Z`);
      date.setUTCDate(date.getUTCDate() + days);
      return date.toISOString().split('T')[0]; // Return YYYY-MM-DD
    },
    dateRangeChanged: function(){
      //console.log('this.slicers.btDateRange: ', this.slicers.btDateRange)
      if(this.dashboard.datePicker.type === 'day')
        return;

      

      if(this.slicers.btDateRange && this.slicers.btDateRange.length === 2){

        // alert(JSON.stringify(this.slicers.btDateRange));

        // const fromDate = new Date(this.slicers.btDateRange[0]);
        // fromDate.setDate(fromDate.getDate() + 1);

        // const toDate = new Date(this.slicers.btDateRange[1]);
        // toDate.setDate(toDate.getDate() + 2);

        this.slicers.filters['date_range']= [];
        this.slicers.filters['date_range'][0] = this.slicers.btDateRange[0]; //this.formatDate(fromDate);;
        this.slicers.filters['date_range'][1] = this.addDaysToDate(this.slicers.btDateRange[1], 1); //this.formatDate(toDate);

        const params = this.convertFiltersToQueryParams();

        if(this.dashboard.filtersDefinition[0].show)
        this.getFilter0FromApi(params).then()
        if(this.dashboard.filtersDefinition[1].show)
          this.getFilter1FromApi(params).then()
        if(this.dashboard.filtersDefinition[2].show)
          this.getFilter2FromApi(params).then()

        this.filtersChanged(null, 'dateRangeChanged');
        //console.log(this.slicers.filters)
      }
    },
    dayChanged: function(){
      if(this.dashboard.datePicker.type === 'range')
        return;

      this.slicers.filters['date'] = this.slicers.btDay;
      // const fromDate = new Date(this.slicers.btDay);
      // fromDate.setDate(fromDate.getDate() + 1);

      // const toDate = new Date(this.slicers.btDay);
      // toDate.setDate(toDate.getDate() + 2);

      this.slicers.filters['date_range']= [];
      this.slicers.filters['date_range'][0] = this.slicers.btDay; //this.formatDate(fromDate);;
      this.slicers.filters['date_range'][1] = this.addDaysToDate(this.slicers.btDay, 1); //this.formatDate(toDate);
      this.filtersChanged(null, 'dayChanged');

      const params = this.convertFiltersToQueryParams();

      if(this.dashboard.filtersDefinition[0].show)
        this.getFilter0FromApi(params).then()
      if(this.dashboard.filtersDefinition[1].show)
        this.getFilter1FromApi(params).then()
      if(this.dashboard.filtersDefinition[2].show)
        this.getFilter2FromApi(params).then()

    },
    hasUserPermission: function(policyName){
      if(this.$store.getters.user.policies.includes(policyName)){
        return true;
      }

      return false;
    },
    applySelectedCellPreFiltersToParams(paramsCopy){
      if(this.slicers.selectedCell && this.slicers.selectedCell.preFilters && Object.keys(this.slicers.selectedCell.preFilters).length > 0){

        for(let k in this.slicers.selectedCell.preFilters){
          if(paramsCopy){
              if(!paramsCopy.toString().includes(k)){
                if(Array.isArray(this.slicers.selectedCell.preFilters[k]))
                  paramsCopy.append(k, this.slicers.selectedCell.preFilters[k].sort().join(','));
                else
                  paramsCopy.append(k, this.slicers.selectedCell.preFilters[k]);
              }

          }
        }

      }
    },
    filterDashboardListAgainstPreferences(){
      const preferences = this.$store.getters.user.preferences;
      if(preferences.biDashboards && preferences.biDashboards.length > 0 && !this.isPowerUser && !this.isAdminUser && !this.isPowerAdmin){ 
        this.dashboards = this.dashboards.filter( item => {
          if(preferences.biDashboards.includes(item.name)){
            return true;
          }
          return false;
        })
      }
    }
  },
  watch: {
    inPreviewMode: {
      async handler(val){
        if(val){
          this.designerShowCreateButton=false;
          this.designerCloneButtonTitle="Back to Designer ";
          this.designerCloneButtonIcon="create";
        } else{
          this.designerShowCreateButton=true;
          this.designerCloneButtonTitle="Clone";
          this.designerCloneButtonIcon="content_copy";
        }

      }
    },
    account: {
          async handler(val){
            this.contacts.customFieldSelections = [];

            const resp = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/v1/dashboards', method: 'GET' })
            this.dashboards = resp.data.sort((a, b) => (a.name > b.name) ? 1 : -1); //resp.data; 
            this.filterDashboardListAgainstPreferences();

            await this.getSchedulerReportList();

            await this.filterDashboardList();

            await this.dashboardChanged();

          },
          deep: true
      },
      'slicers.isGurl': {
        handler(val) {

          if(val){
              this.slicers.isSeed = false;
              this.contacts.show = false;
              this.slicers.distinct = false;
              this.events.show = false;
          } else {
              this.slicers.isSeed = false;
              this.contacts.show = false;
              this.slicers.distinct = true;
              this.events.show = false;
          }

        }
      },
      'slicers.btDay': {
        handler() {
              this.dayChanged();
          }
      },
      'slicers.btDateRange': {
          handler() {
              this.dateRangeChanged();
          }
      },
      'contacts.pagination': {
          handler() {
              this.getContactsFromApi(true).then(data => {
                  //console.log("GET_CONTACTS");
                  //this.contacts = data.items;
                  //this.totalContacts = data.total;
              });
          },
          deep: true
      },
      'events.pagination': {
          handler() {
              this.getEventsFromApi(true).then(data => {
                  //console.log("GET_EVENTS");
                  //this.contacts = data.items;
                  //this.totalContacts = data.total;
              });
          },
          deep: true
      }
  },
  computed: {
    gurlOff: function(){
      return !this.slicers.isGurl;
    },
    hasNavbar: function(){
      if(this.$route.query.token){
        return false;
      }
      else{
        return true;
      }
    },
    getReportList: function(){
      //console.log([...this.filterReportList, ...this.chartReportList]);
      return [...this.filterReportList, ...this.chartReportList];
    },
    isPowerAdmin: function(){
      return this.$store.getters.user.isPowerAdmin;
    },
    isAdminUser: function(){
      return this.$store.getters.user.isAdminUser;
    },
    isPowerUser: function(){
      return this.$store.getters.user.isPowerUser;
    },
    isParentAccount: function(){
      if(this.$store.getters.user.parent == null)
        return true;

      return false;
    },
    account : function(){
        //console.log('user object: ', this.$store.getters.user);
        return this.$store.getters.user.account;
    },
    parent: function(){
        if(this.$store.getters.user.parent == 0 || this.$store.getters.user.parent == null)
          return 'null';
        return this.$store.getters.user.parent;
    },
    canShare: function(){

      if(this.dashboardDDL.author.trim().toLowerCase() != this.$store.getters.user.email.trim().toLowerCase())
        return false;

      return true;
    },
    contactStandardFieldsSorted: function(){
      // eslint-disable-next-line
      return this.contacts.standardFields.sort();
    },
    eventHeadersSorted: function(){
      // eslint-disable-next-line
      return this.events.headers.sort();
    },
    contactListHeaders: function(){
      //console.log('contactStandardFieldSelections: ', this.contactStandardFieldSelections);
      let mappedHeaders1 = this.dashboard.selectedContactsStandardFields.map( val => {
        return { text: val.toUpperCase(), sortable: true, align: "left",value: val }
      })
      let mappedHeaders2 = this.contacts.customFieldSelections.map( val => {
        return { text: val.toUpperCase(), sortable: true, align: "left",value: val }
      })
      const actionArray = [{ text: 'Action', sortable: false, align: "left",value: 'action' }];

      return [...actionArray, ...mappedHeaders1, ...mappedHeaders2]
    },
    eventListHeaders: function(){
      //console.log('contactStandardFieldSelections: ', this.contactStandardFieldSelections);
      let mappedHeaders = this.dashboard.selectedEventsFields.map( val => {
        if(val.toLowerCase().endsWith('_id'))
          return { text: val.replace('_id','').toUpperCase(), sortable: true, align: "left",value: val.replace('_id','_name') }
        else
          return { text: val.toUpperCase(), sortable: true, align: "left",value: val }
      })

      const actionArray = [{ text: 'Action', sortable: false, align: "left",value: 'action' }];
      return [...actionArray, ...mappedHeaders];
    }
  }
}
</script>


